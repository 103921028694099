<template>
  <div
    v-observe-visibility="visibilityChanged"
    class="flex flex-wrap mx-auto relative text-white w-full overflow-hidden"
  >
    <div ref="header" class="hidden flex flex-wrap w-full">
      <div class="md:mb-64 md:pl-16 my-32 px-6 w-full z-20">
        <div
          class="font-hel uppercase font-bold lg:text-5xl md:text-4xl text-2xl xl:text-7xl "
        >
          <div class="relative max-w-4xl xl:max-w-6xl">
            <div class="wash bg-yellow-500 absolute w-full h-full"></div>
            <span class="callout"
              ><span
                class="text-transparent"
                style="text-shadow: -1px -1px 0 #fbbd01, 1px -1px 0 #fbbd01, -1px 1px 0 #fbbd01, 1px 1px 0 #fbbd01; color: #181818;"
                >Sutura</span
              >
              is a web design</span
            >
          </div>
          <div
            class="relative w-full md:w-4/5 lg:w-10/12 xl:w-4/5 md:-mt-4 max-w-5xl xl:max-w-5xl"
          >
            <div class="wash bg-yellow-500 absolute w-full h-full"></div>
            <span class="callout">{{ line2 }}</span>
          </div>
        </div>

        <div class="mt-6 flex">
          <CTAButton
            v-for="(item, index) in buttons"
            :key="index"
            :text="item.label"
            :link="item.link"
            class="mr-4"
            :animation-delay="0.5"
          />
        </div>
      </div>
      <div
        ref="video"
        class="relative my-auto mx-auto my-32 w-full z-20 md:px-16 md:rounded-lg"
      >
        <div class="embed-container relative md:rounded-lg">
          <picture class="absolute w-full bg-gray-800 md:rounded-lg">
            <source
              class="w-full"
              :srcset="require('~/assets/img/video-thumbnail.jpg?webp')"
              type="image/webp"
            />
            <source
              class="w-full"
              :srcset="require('~/assets/img/video-thumbnail.jpg')"
              type="image/jpeg"
            />
            <img
              class="w-full"
              :src="require('~/assets/img/video-thumbnail.jpg')"
            />
          </picture>
          <iframe
            class="bg-cover bg-center"
            src=""
            :data-src="videoSrc"
            frameborder="0"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TimelineLite, Power4 } from 'gsap'
import CTAButton from '@/components/widgets/buttons/circular'

export default {
  components: {
    CTAButton
  },
  props: {
    line1: {
      type: String,
      required: true
    },
    line2: {
      type: String,
      required: true
    },
    videoSrc: {
      type: String,
      required: true
    },
    buttons: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isVisible: true,
      animated: false,
      animation: new TimelineLite()
    }
  },
  mounted() {
    this.$nextTick(function() {
      function init() {
        const vidDefer = document.getElementsByTagName('iframe')
        for (let i = 0; i < vidDefer.length; i++) {
          if (vidDefer[i].getAttribute('data-src')) {
            vidDefer[i].setAttribute(
              'src',
              vidDefer[i].getAttribute('data-src')
            )
          }
        }
      }

      setTimeout(function() {
        init()
      }, 2000)

      const thisObj = this
      this.animation.set(thisObj.$refs.header, {
        opacity: 0,
        onComplete() {
          thisObj.$refs.header.classList.remove('hidden')
        }
      })
    })
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      const thisObj = this
      if (isVisible && !this.animated) {
        this.animated = true
        this.animation
          .set(
            thisObj.$refs.header,
            {
              opacity: 1
            },
            'start'
          )
          .set('.callout', { opacity: 0 }, 'start')
          .from(thisObj.$refs.header, 0.5, { opacity: 0 })
          .staggerFrom(
            '.wash',
            0.25,
            {
              scaleX: 0,
              transformOrigin: 'left',
              ease: Power4.easeOut
            },
            0.12,
            'start'
          )
          .set('.callout', { opacity: 1 })
          .staggerTo(
            '.wash',
            0.5,
            {
              scaleX: 0,
              transformOrigin: 'right',
              ease: Power4.easeOut
            },
            0.12
          )
          .from(thisObj.$refs.video, 0.5, {
            delay: 0.25,
            scale: 0.8,
            opacity: 0,
            ease: Power4.easeOut
          })
      }
    }
  }
}
</script>
