<template>
  <div>
    <Header
      :line1="pageContent.header['line-1']"
      :line2="pageContent.header['line-2']"
      :video-src="pageContent.header.video"
      :buttons="pageContent.header['cta-buttons']"
    />

    <div class="w-full my-32 md:my-48">
      <Marquee :data="{ text: 'Marketing', boxes: 12 }" />
      <Marquee :data="{ text: 'Design', boxes: 12 }" />
      <Marquee :data="{ text: 'Web', boxes: 18 }" />
      <Marquee :data="{ text: 'Branding', boxes: 12 }" />
    </div>

    <div class="flex flex-wrap text-white px-6 md:px-16">
      <section-header :text="'FEATURED WORKS'" />
      <div class="flex flex-wrap md:flex-no-wrap lg:mx-auto md:mt-8 w-full">
        <div class="w-full md:w-1/3 md:px-6">
          <s-tile :data="featured[0]" />
        </div>
        <div class="w-full md:w-1/3 md:px-6">
          <s-tile :data="featured[1]" />
        </div>
        <div class="w-full md:w-1/3 md:px-6">
          <s-tile :data="featured[2]" />
        </div>
      </div>
      <div class="flex lg:mx-auto mt-6 w-full md:px-6">
        <h-tile :data="featured[3]" />
      </div>
    </div>

    <div class="flex flex-wrap text-white px-6 md:px-16 mt-32 lg:mt-56">
      <section-header :border="false" :text="'Featured News'" />
      <div class="w-full border-yellow-500"></div>
    </div>
    <div class="flex flex-wrap mb-32 lg:mb-64 px-6 md:px-16">
      <news-tile
        v-for="(item, index) in articles.slice(0, 3)"
        :key="index"
        :article="item"
        :border="index !== 0"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Header from '@/components/home/Header'
import Marquee from '@/components/widgets/misc/marquee'
import HTile from '@/components/works/tiles/Horizontal'
import STile from '@/components/works/tiles/Square'
import NewsTile from '@/components/news/tiles/Responsive'
import SectionHeader from '@/components/widgets/headers/Section'

export default {
  components: {
    Header,
    Marquee,
    HTile,
    STile,
    NewsTile,
    SectionHeader
  },
  async asyncData({ params, payload }) {
    if (payload) return { pageContent: payload }
    else
      return {
        pageContent: await require(`~/assets/content/pages/home.json`)
      }
  },
  computed: mapGetters({
    featured: 'works/featured',
    articles: 'news/get'
  })
}
</script>
