<template>
  <nuxt-link
    v-observe-visibility="visibilityChanged"
    :to="'/works/' + data.slug"
    class="hidden flex md:mt-5 pb-full w-full md:pb-1/6 md:w-full relative overflow-hidden"
  >
    <client-only>
      <picture class="absolute w-full opacity-25">
        <source
          :srcset="
            $vssWidth > 768
              ? require('~/assets' + data['h-thumbnail'] + '?webp')
              : require('~/assets' + data['s-thumbnail'] + '?webp')
          "
          type="image/webp"
        />
        <source
          class="w-full"
          :srcset="
            $vssWidth > 768
              ? require('~/assets' + data['h-thumbnail'])
              : require('~/assets' + data['s-thumbnail'])
          "
          type="image/jpeg"
        />
        <img
          class="w-full"
          :src="
            $vssWidth > 768
              ? require('~/assets' + data['h-thumbnail'])
              : require('~/assets' + data['s-thumbnail'])
          "
        />
      </picture>
    </client-only>
    <div
      class="uppercase absolute pin-center tracking-widest font-hel font-bold text-2xl lg:text-3xl xl:text-4xl text-center text-white w-full"
    >
      {{ data.title }}
    </div>
  </nuxt-link>
</template>
<script>
import { TimelineLite } from 'gsap'
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'

export default {
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: true,
      isVisible: true,
      animated: false,
      animation: new TimelineLite()
    }
  },
  mounted() {
    this.$nextTick(function() {
      const thisObj = this
      this.animation.set(this.$el, {
        opacity: 0,
        onComplete() {
          thisObj.$el.classList.remove('hidden')
        }
      })
    })
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible && !this.animated) {
        this.animated = true

        if (window.screen.width >= 640) {
          this.animation
            .set(this.$el, {
              opacity: 1
            })
            .from(this.$el, 0.5, {
              opacity: 0,
              scale: 0.8
            })
        } else {
          this.animation
            .set(this.$el, {
              opacity: 1
            })
            .from(this.$el, 0.5, {
              opacity: 0,
              scale: 0.8,
              y: '+=100'
            })
        }
      }
    }
  }
}
</script>
