<template>
  <nuxt-link
    v-observe-visibility="visibilityChanged"
    :to="'/news/' + article.slug"
    :class="{ 'border-t': border }"
    class="text-white font-hel cursor-pointer my-8 md:my-2 w-full flex flex-wrap md:flex-no-wrap pt-12 border-yellow-500 md:pb-24"
  >
    <div class="md:pb-1/4 md:w-2/3 w-full pb-3/5 relative overflow-hidden">
      <picture class="absolute w-full opacity-25">
        <source
          class="w-full"
          :srcset="require('~/assets' + article.thumbnail + '?webp')"
          type="image/webp"
        />
        <source
          class="w-full"
          :srcset="require('~/assets' + article.thumbnail)"
          type="image/jpeg"
        />
        <img class="w-full" :src="require('~/assets' + article.thumbnail)" />
      </picture>
    </div>
    <div class="w-full flex flex-wrap">
      <div
        class="font-medium uppercase font-hel md:ml-16 overflow-hidden relative text-2xl md:text-3xl md:w-10/12"
      >
        {{ article.title }}
      </div>
      <div
        class="flex font-hel md:ml-16 overflow-hidden relative w-full mt-auto"
      >
        <div class="uppercase">{{ article.category }}</div>
        <div class="ml-5">
          <reading-time :content="totalTextContent"></reading-time>
        </div>
      </div>
    </div>
  </nuxt-link>
</template>
<script>
import { TimelineLite } from 'gsap'
import ReadingTime from '@/components/global/ReadingTime'
export default {
  components: {
    ReadingTime
  },
  props: {
    article: {
      type: Object,
      required: true
    },
    border: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isVisible: true,
      animated: false,
      animation: new TimelineLite()
    }
  },
  computed: {
    totalTextContent() {
      let totalText = ''
      for (let i = 0; i < this.article.widgets.length; i++) {
        if (this.article.widgets[i].type.split(/-(.+)/)[0] === 'text') {
          totalText += this.article.widgets[i]['widget-data'].body
        }
      }
      return totalText
    }
  },
  mounted() {
    // this.$nextTick(function() {
    //   const thisObj = this
    //   this.animation.set(this.$el, {
    //     opacity: 0,
    //     onComplete() {
    //       thisObj.$el.classList.remove('hidden')
    //       thisObj.$el.classList.remove('lg:hidden')
    //     }
    //   })
    // })
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      // const thisObj = this
      // if (isVisible && !this.animated) {
      //   this.animated = true
      //   this.animation
      //     .set(this.$el, {
      //       opacity: 1
      //     })
      //     .set(
      //       [
      //         thisObj.$refs.title,
      //         thisObj.$refs['sub-title'],
      //         thisObj.$refs.description,
      //         thisObj.$refs['read-time']
      //       ],
      //       {
      //         opacity: 0
      //       }
      //     )
      //     .from(thisObj.$refs.thumbnail, 0.25, {
      //       opacity: 0,
      //       scale: 0.8
      //     })
      //     .from(thisObj.$refs.info, 0.25, {
      //       scaleX: 0,
      //       transformOrigin: 'left',
      //       ease: Power4.easeOut,
      //       clearProps: 'all'
      //     })
      //     .to(
      //       [
      //         thisObj.$refs.title,
      //         thisObj.$refs['sub-title'],
      //         thisObj.$refs.description,
      //         thisObj.$refs['read-time']
      //       ],
      //       0.5,
      //       {
      //         opacity: 1
      //       }
      //     )
      // }
    }
  }
}
</script>
