<template>
  <div
    ref="buttonWrap"
    v-observe-visibility="visibilityChanged"
    class="hidden relative md:w-48"
  >
    <div
      ref="button"
      class="border border-yellow-100 cursor-pointer duration-200 font-hel hover:bg-yellow-300 hover:text-black mx-auto py-2 md:py-4 rounded-full shadow-lg text-center text-white transition-colors w-40 md:w-48"
      @click="$router.push(String(link))"
    >
      {{ text }}
    </div>
  </div>
</template>
<script>
import { gsap, Power4, TimelineLite } from 'gsap'
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'

export default {
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    link: {
      type: String,
      required: true
    },
    text: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isVisible: true,
      animated: false,
      animation: new TimelineLite()
    }
  },
  beforeDestroy() {
    this.$refs.buttonWrap.removeEventListener('mousemove', this.mouseMove)
    this.$refs.buttonWrap.removeEventListener('mouseleave', this.mouseLeave)
  },
  mounted() {
    this.$nextTick(function() {
      const thisObj = this
      this.animation.set(thisObj.$refs.buttonWrap, {
        opacity: 0,
        onComplete() {
          thisObj.$refs.buttonWrap.classList.remove('hidden')
          thisObj.$refs.buttonWrap.addEventListener(
            'mousemove',
            thisObj.mouseMove
          )
          thisObj.$refs.buttonWrap.addEventListener(
            'mouseleave',
            thisObj.mouseLeave
          )
        }
      })
    })
  },
  methods: {
    mouseMove(e) {
      const thisObj = this

      const circle = thisObj.$refs.button
      const width = circle.offsetWidth
      const height = circle.offsetHeight
      const x = e.clientX - circle.getBoundingClientRect().left
      const y = e.clientY - circle.getBoundingClientRect().top

      if (this.$vssWidth > 768) {
        gsap.to(circle, 0.6, {
          x: ((x - width / 2) / width) * 25,
          y: ((y - height / 2) / height) * 25,
          ease: Power4.easeOut
        })
      }
    },
    mouseLeave(e) {
      const thisObj = this

      const circle = thisObj.$refs.button
      if (this.$vssWidth > 768) {
        gsap.to(circle, 0.6, {
          x: 0,
          y: 0,
          ease: Power4.easeOut
        })
      }
    },
    visibilityChanged(isVisible, entry) {
      const thisObj = this
      if (isVisible && !this.animated) {
        this.animated = true
        this.animation
          .set(thisObj.$refs.buttonWrap, {
            opacity: 1
          })
          .from(thisObj.$refs.button, 0.5, {
            delay: 0.75,
            alpha: 0,
            ease: Power4.easeOut
          })
      }
    }
  }
}
</script>
