<template>
  <div
    v-observe-visibility="visibilityChanged"
    class="hidden text-white uppercase font-extrabold w-full text-2xl md:text-4xl xl:text-5xl border-yellow-500 border-b pb-2"
  >
    {{ text }}
  </div>
</template>
<script>
import { gsap, Power3, TimelineLite } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    border: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      isVisible: true,
      animated: false,
      animation: new TimelineLite()
    }
  },
  mounted() {
    this.$nextTick(function() {
      const thisObj = this
      this.animation.set(this.$el, {
        opacity: 0,
        onComplete() {
          thisObj.$el.classList.remove('hidden')
        }
      })
    })
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      const thisObj = this
      if (isVisible && !this.animated) {
        this.animated = true
        this.animation.to(thisObj.$el, 1.5, {
          opacity: 1,
          ease: Power3.easeOut
        })
      }
    }
  }
}
</script>
