<template>
  <div ref="wrapper" class="w-full overflow-x-hidden opacity-0">
    <div
      ref="marquee"
      class="tracking-widest wrapper h-20 md:h-32 flex font-hel"
      style="margin-left: -200px"
    >
      <div ref="boxes" class="relative my-auto">
        <div
          v-for="(item, index) in data.boxes"
          :key="index"
          ref="box"
          class="box px-5 text-2xl md:text-6xl "
          :class="[index % 2 === 0 ? 'text-white' : 'outline']"
        >
          {{ data.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { gsap, TweenLite, Linear, Power3, TimelineLite } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      master: null,
      boxWidth: null,
      totalWidth: null,
      no01: null,
      currentDir: null,
      dirFromLeft: null,
      dirFromRight: null,
      mod: null,
      animation: null,
      scrollAnimation: new TimelineLite(),
      speed: null,
      resize: null,
      previousWinWidth: null,
      scrollDirection: 1,
      lastScrollDir: 'down',
      time: 1
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  },
  mounted() {
    this.$nextTick(function() {
      ScrollTrigger.defaults({
        toggleActions: 'restart pause resume pause'
      })
      const thisObj = this
      setTimeout(function() {
        thisObj.$el.classList.remove('opacity-0')
        thisObj.scrollAnimation.from(
          thisObj.$refs.marquee,
          {
            scrollTrigger: {
              trigger: thisObj.$refs.marquee,
              start: '-300% center',
              scrub: 1
            },
            x: '-=200',
            ease: Power3.easeOut,
            alpha: 0,
            duration: 3
          },
          0
        )
      }, 1500)

      window.addEventListener('resize', this.onResize)
      window.addEventListener('scroll', this.onScroll)

      this.previousWinWidth = window.innerWidth
      this.setMarquee()
      this.animateMarquee('left')
    })
  },
  methods: {
    onScroll() {
      const thisObj = this
      const st = window.pageYOffset || document.documentElement.scrollTop
      let scrollDir = null
      if (st > thisObj.lastScrollTop) {
        // downscroll code
        scrollDir = 'down'
      } else {
        // upscroll code
        scrollDir = 'up'
      }

      if (thisObj.lastScrollDir !== scrollDir) {
        if (scrollDir === 'down') {
          thisObj.animateMarquee('left')
          thisObj.lastScrollDir = scrollDir
        } else {
          thisObj.animateMarquee('right')
          thisObj.lastScrollDir = scrollDir
        }
      }

      thisObj.lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
    },
    onResize() {
      const thisObj = this
      clearTimeout(thisObj.resize)
      thisObj.resize = setTimeout(function() {
        if (window.innerWidth !== thisObj.previousWinWidth) {
          thisObj.previousWinWidth = window.innerWidth
          thisObj.resetMarquee()
        }
      }, 1000)
    },
    resetMarquee() {
      this.animation.pause()
      this.setMarquee()
      this.marquee()
    },
    setMarquee() {
      const thisObj = this
      this.speed = 80
      this.boxWidth = this.$refs.box[0].offsetWidth
      this.totalWidth = this.boxWidth * this.$refs.box.length //  * n of boxes
      this.no01 = this.$refs.marquee.querySelectorAll('.box')
      this.dirFromLeft = '+=' + this.totalWidth
      this.dirFromRight = '-=' + this.totalWidth

      this.mod = gsap.utils.wrap(0, this.totalWidth)

      this.$refs.boxes.setAttribute('style', 'left: -' + this.boxWidth + 'px')

      gsap.set(this.no01, {
        x(i) {
          return i * thisObj.boxWidth
        }
      })
    },
    marquee() {
      const thisObj = this

      thisObj.animation = TweenLite.to(thisObj.no01, {
        x: thisObj.currentDir,
        modifiers: {
          x: x => thisObj.mod(parseFloat(x)) + 'px'
        },
        duration: thisObj.speed,
        ease: Linear.easeNone,
        repeat: -1
      })
    },
    animateMarquee(dir) {
      const thisObj = this
      if (dir === 'left') {
        thisObj.currentDir = thisObj.dirFromLeft
      } else {
        thisObj.currentDir = thisObj.dirFromRight
      }
      thisObj.marquee()
    }
  }
}
</script>
<style scoped>
.wrapper {
  width: 200%;
  overflow: hidden;
}
.wrapper .box {
  position: absolute;
  height: 50px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
}

.outline {
  text-shadow: -1px -1px 0 #fbbd01, 1px -1px 0 #fbbd01, -1px 1px 0 #fbbd01,
    1px 1px 0 #fbbd01;
  color: #181818;
}
</style>
